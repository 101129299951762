import * as React from 'react';
import type { LoaderFunction } from 'remix';
import { Button } from 'wally-design-system';
import { useLoaderData, Link } from 'remix';
import { getAccountId } from '~/utils/session.server';
import { useFetch } from '~/utils/useFetch.server';
type LoaderData = { id: string | null; version: string | null };
import { Layout, Ticker, LandingHero, Footer, QA, CardFeature } from 'wally-design-system';
import { useNotification } from '~/context';
// import type { User } from ' ~/utils/data.server';

export let loader: LoaderFunction = async ({ context, request }) => {
  const { env, sessionStorage } = context
  const { API_HOST } = env;
  let accountId = await getAccountId(request, sessionStorage);
  const value: any = await useFetch({
    url: `${API_HOST}/info`,
  });

  
  let version = value ? value.data.version : 'unknown';
  let data: LoaderData = { id: accountId, version: version };
  return data;
};

export default function Index() {
  let data = useLoaderData<LoaderData>();
  const { openNotification } = useNotification()

  React.useEffect(() => {
    // openNotification()
  }, [])

  return (
    <>
    <div className="h-screen">
      <Layout variant="large" customCss="h-full">
        <LandingHero />
      </Layout>
    </div>
    <Ticker />
    <QA />
    <Layout variant="large" customCss="mb-[40px]">
      <div className="mt-[40px] flex space-x-[8px]">
        <CardFeature 
          color='blue-500'
          caption={
            <div className="text-subheading-medium">
              <span className="text-neutral-700">Discover a new routine or </span><span className="text-blue-500">find hobbies you love</span>
            </div>
          }
          icon='compass' component={Link} to='/discover'/>
          <CardFeature
            color='secondary-400'
            caption={
              <div className="text-subheading-medium">
                <span className="text-neutral-700">Got questions on how </span><span className="text-secondary-400">we include blockchain</span>
              </div>
            }
          component={Link} icon='faq' to='/faq'/>
      </div>
    </Layout>
    </>
  );
}
